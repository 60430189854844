import React, { FC } from 'react';

import { Text } from '@/components/typographies';
import { Button, Input } from '@/components/forms';
import { COLORS } from '@/styles/colors';
import * as style from '@/pages/components/LocationSettingModal/styles';
import LoadingList from '@/pages/components/LoadingList';
import EmptyList from '@/pages/components/EmptyList';
import { Location, SelectedLocation } from '@/context/doctor/types';

import CloseIcon from '../../../assets/icons/ic_close.svg';
import SearchIcon from '../../../assets/icons/ic_search.svg';
import TargetIcon from '../../../assets/icons/ic_target.svg';
import RemoveIcon from '../../../assets/icons/ic_remove.svg';

interface locationSettingModalProps {
  selectedLocation: SelectedLocation;
  handleLocation: (value: Location) => void;
  searchKeyword: (value: string) => void;
  resetKeyword: () => void;
  closeLocationSettingModal: () => void;
  getCurrentLocation: () => void;
  currentLocationLoading: boolean;
  kakaoLocations: Location[];
}

const LocationSettingModal: FC<locationSettingModalProps | any> = (props) => {
  const {
    selectedLocation,
    handleLocation,
    searchKeyword,
    resetKeyword,
    closeLocationSettingModal,
    getCurrentLocation,
    currentLocationLoading,
    kakaoLocations
  } = props;

  return (
    <div className={style.container}>
      <header className={style.header}>
        <CloseIcon
          width={24}
          height={24}
          className={style.closeIcon}
          onClick={closeLocationSettingModal}
          color={COLORS.GRAY_90}
          alt="굿닥 비대면진료 닫기"
        />
      </header>
      <div className={style.topContent}>
        <p className={style.title}>
          <Text type="heading3_700">위치 설정</Text>
        </p>
        <Input
          layoutClassName={style.searchInput}
          onChange={(e) => searchKeyword(e.target.value)}
          value={selectedLocation.keyword}
          placeholder="시/군/구를 입력해주세요"
          leftComponent={
            <SearchIcon
              color={COLORS.GRAY_60}
              className={style.searchIcon}
              alt="굿닥 비대면진료 검색"
            />
          }
          rightComponent={
            selectedLocation.keyword && (
              <RemoveIcon onClick={resetKeyword} alt="굿닥 비대면진료 삭제" />
            )
          }
        />
        <Button
          styleType="grayTonal"
          className={style.currentLocationButton}
          onClick={getCurrentLocation}
        >
          <TargetIcon
            width={20}
            height={20}
            color={COLORS.GRAY_90}
            alt="굿닥 비대면진료 위치설정"
          />
          &nbsp;현위치로 설정
        </Button>
      </div>
      <ul className={style.listContainer}>
        {currentLocationLoading ? (
          <LoadingList headerSize={230} />
        ) : selectedLocation.keyword !== '' ? (
          kakaoLocations.length > 0 ? (
            kakaoLocations.map((item: Location) => (
              <li
                key={item.id}
                className={style.locationContainer}
                onClick={() => {
                  handleLocation(item);
                  closeLocationSettingModal();
                }}
              >
                <p>
                  <Text type="body1_500" color={COLORS.GRAY_80}>
                    {item.place_name}
                  </Text>
                </p>
                <Text type="caption_400" color={COLORS.GRAY_70}>
                  {item.road_address_name}
                </Text>
              </li>
            ))
          ) : (
            <EmptyList headerSize={230} content="검색 결과가 없습니다" />
          )
        ) : null}
      </ul>
    </div>
  );
};
export default LocationSettingModal;
