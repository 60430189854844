import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = css`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 640px;
  height: 100vh;
  background: ${COLORS.WHITE};
`;

export const closeIcon = css`
  cursor: pointer;
`;

export const searchIcon = css`
  margin-right: 8px;
`;

export const currentLocationButton = css`
  color: ${COLORS.GRAY_100};
`;

export const header = css`
  padding: 12px 20px;
  height: 48px;
`;

export const topContent = css`
  padding: 20px;
  border-bottom: 1px solid ${COLORS.GRAY_30};
`;

export const title = css`
  margin-bottom: 12px;
`;

export const listContainer = css`
  overflow: auto;
  padding: 0 20px;
`;

export const searchInput = css`
  margin-bottom: 12px;
  background: ${COLORS.GRAY_10};
  border: none;
  input {
    background: ${COLORS.GRAY_10};
    &::placeholder {
      color: ${COLORS.GRAY_60};
    }
  }
  &:hover,
  &:active,
  &:focus-within {
    border: none !important;
  }
`;

export const locationContainer = css`
  padding: 12px 0;
  cursor: pointer;
`;
